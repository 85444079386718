import React from "react"
import style from "./Hero.module.scss"
import { Row, Col } from "react-flexbox-grid"

export default ({
  title,
  img,
  imgLabel,
  tag,
  headquarters,
  text,
  author,
  pdf,
}) => (
  <Col xs={12} sm={10} md={11} lg={6}>
    <Col xs={12} className={style.caseHeader}>
      <Row center="xs" between="sm" bottom="sm" className={style.boxTitle}>
        <Col xs={7} sm={6} md={6} lg={6}>
          <span className={style.title}>{title}</span>
          <span className={style.tag}>{tag}</span>
        </Col>
        <Col xs={7} sm={6} md={6} lg={6}>
          <img className={style.icon} src={img} alt={imgLabel} />
          <span className={style.headquarters}>{headquarters}</span>
        </Col>
      </Row>
      <span className={style.text}>{text}</span>
      <span className={style.author}>{author}</span>
      <a href={pdf} target="_blank" rel="noopener noreferrer">
        Read more
      </a>
    </Col>
  </Col>
)
