import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CaseDetail from "./CaseDetail"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/cases/" } }
          sort: { fields: frontmatter___title, order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                title
                logo
                label_logo
                tag
                headquarters
                author_signature
                pdf
              }
              excerpt(pruneLength: 250)
            }
          }
        }
      }
    `}
    render={data =>
      data.allMarkdownRemark.edges.map(({ node }) => (
        <CaseDetail
          key={node.frontmatter.label_logo}
          title={node.frontmatter.title}
          img={node.frontmatter.logo}
          imgLabel={node.frontmatter.label_logo}
          tag={node.frontmatter.tag}
          headquarters={node.frontmatter.headquarters}
          text={node.excerpt}
          author={node.frontmatter.author_signature}
          pdf={node.frontmatter.pdf}
        />
      ))
    }
  />
)
