import React from "react"
import styles from "./Partners.module.scss"
import { Row } from "react-flexbox-grid"
import PartnersCard from "./PartnersCard"

export default () => (
  <div className={styles.content}>
    <div className={styles.container}>
      <h2>Customers and Partners</h2>
      <Row around="xs" middle="xs" className={styles.box}>
        <PartnersCard />
      </Row>
    </div>
  </div>
)
