import React from "react"
import style from "./Hero.module.scss"
import { Row, Col } from "react-flexbox-grid"
import Cases from "./Cases"

export default () => (
  <section className={style.caseSection}>
    <div className={style.caseHeaderImg}></div>
    <div className={style.container}>
      <Row center="xs">
        <Col xs={12} className={style.caseTitle}>
          <h1>Case studies</h1>
          <h2>Read about UpdateHub case studies</h2>
        </Col>
        <Cases />
      </Row>
    </div>
  </section>
)
