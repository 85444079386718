import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styles from "./Partners.module.scss"
import { Col } from "react-flexbox-grid"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/partners/" } }
          sort: { fields: frontmatter___alt, order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                alt
                src
                link
                pdf
              }
            }
          }
        }
      }
    `}
    render={data =>
      data.allMarkdownRemark.edges.map(({ node }) => {
        const pdfLink =
          node.frontmatter.pdf !== null ? (
            <a
              className={styles.pdfLink}
              href={node.frontmatter.pdf}
              target="_blank"
              rel="noopener noreferrer"
            >
              case study
            </a>
          ) : null

        return (
          <Col
            key={node.frontmatter.alt}
            xs={6}
            sm={4}
            md={3}
            lg={2}
            className={styles.valuesBox}
          >
            {pdfLink}
            <a
              href={node.frontmatter.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt={node.frontmatter.alt} src={node.frontmatter.src} />
            </a>
          </Col>
        )
      })
    }
  />
)
