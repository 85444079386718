import React from "react"
import Layout from "../components/Layout.js"
import Hero from "../components/cases/Hero.js"
import Footer from "../components/index/Footer.js"
import FooterContact from "../components/index/FooterContact.js"
import Partners from "../components/cases/Partners.js"

export default () => (
  <Layout>
    <Hero />
    <Partners />
    <FooterContact />
    <Footer />
  </Layout>
)
